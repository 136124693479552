<template>
  <div>
    <b-modal
      v-model="isShow"
      id="modal-xl"
      size="xl"
      no-close-on-backdrop
      :title="$t('attendance.group.title')"
      scrollable
    >
      <div class="row">
        <div class="col-md-12">
          <b-button
            variant="primary"
            class="float-right"
            @click="showGroupDetail(0)"
          >
            {{ $t("common.addNew") }}
          </b-button>
        </div>
        <div class="col-md-12 mt-3">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th scope="col">{{ $t("common.nonumber") }}</th>
                <th scope="col">{{ $t("attendance.group.groupID") }}</th>
                <th scope="col">{{ $t("attendance.group.groupName") }}</th>
                <th scope="col">{{ $t("attendance.group.description") }}</th>
                <th scope="col">{{ $t("attendance.group.numberOfPeople") }}</th>
                <th scope="col">{{ $t("common.action") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in grid_band_group_data_list"
                :key="item + index"
              >
                <td>{{ item.nonumber }}</td>
                <td>{{ item.group_id }}</td>
                <td>{{ item.group_name }}</td>
                <td>{{ item.group_description }}</td>
                <td>{{ item.number_of_people }}</td>
                <td>
                  <div class="btn-group" style="display: flex">
                    <b-button
                      variant="primary"
                      type="button"
                      class="btn btn-info"
                      @click="showGroupDetail(item.id, item)"
                      style="margin-right: 5px"
                      size="sm"
                    >
                      <i class="fa fa-edit"></i>
                    </b-button>
                    <b-button
                      type="button"
                      class="btn btn-danger"
                      @click="onShowModalConfirmDeleteGroup(item)"
                      size="sm"
                    >
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </b-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button class="float-right" @click="isShow = false">
            {{ $t("common.close") }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- group detail modal -->
    <b-modal
      v-model="isShowGroupDetail"
      id="modal-lg"
      size="lg"
      no-close-on-backdrop
      :title="bandGroupTitle"
      v-bind:hide-footer="true"
      scrollable
    >
      <div class="row">
        <div class="col-md-12 pb-3 border-bottom">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="email">{{ $t("attendance.group.groupID") }}</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="bandGroupData.groupID"
                  :disabled="bandGroupData.id !== 0"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="email">{{
                  $t("attendance.group.groupName")
                }}</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="bandGroupData.groupName"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="email">{{
                  $t("attendance.group.description")
                }}</label>
                <textarea
                  class="form-control"
                  type="text"
                  rows="6"
                  v-model="bandGroupData.description"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="footer">
            <b-button class="float-left" @click="isShowGroupDetail = false">
              {{ $t("common.close") }}
            </b-button>
            <b-button
              variant="primary"
              class="float-right btn btn-success"
              @click="onSaveGroup"
            >
              {{ $t("common.confirm") }}
            </b-button>
          </div>
        </div>
        <!-- user in group -->
        <div class="box col-md-12 mt-3" v-if="bandGroupData.id != 0">
          <div class="row">
            <div class="col-md-12 pb-3">
              <span class="box-title"
                ><strong>{{
                  $t("attendance.group.userInGroup")
                }}</strong></span
              >
              <b-button
                variant="primary"
                class="float-right"
                @click="showModalAddUserInGroup()"
              >
                {{ $t("attendance.group.addNewUser") }}
              </b-button>
            </div>
          </div>
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th scope="col">{{ $t("common.nonumber") }}</th>
                <th scope="col">{{ $t("userProfile.fullName") }}</th>
                <th scope="col">{{ $t("userProfile.email") }}</th>
                <th scope="col">{{ $t("userProfile.phone") }}</th>
                <th scope="col">{{ $t("userProfile.companyName") }}</th>
                <th scope="col">{{ $t("userProfile.companyPosition") }}</th>
                <th scope="col">{{ $t("common.action") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in userInGroupList" :key="item + index">
                <td>{{ item.nonumber }}</td>
                <td>{{ item.full_name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.company_name }}</td>
                <td>{{ item.company_position }}</td>
                <td>
                  <div class="btn-group" style="display: flex">
                    <b-button
                      type="button"
                      class="btn"
                      :class="'btn-danger'"
                      data-toggle="dropdown"
                      @click="onShowModalRemoveUser(item)"
                      size="sm"
                    >
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </b-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- End body -->
    </b-modal>
    <!-- modal add user to group -->
    <b-modal
      ref="modal-add-user-to-group"
      id="modal-add-user-to-group"
      :title="$t('attendance.group.selectUser')"
      no-close-on-backdrop
    >
      <select-option-search-user-not-in-event
        :item="itemCustomerGroupBand"
        :placeHolder="$t('attendance.group.selectUser')"
        :dropdown_titleLte="$t('attendance.group.selectUser')"
        ref="selectAddNewGroup"
        @onchangeselect="onchangeselect"
      ></select-option-search-user-not-in-event>
      <div class="col-12 text-center" v-if="errorAddUserInGroup != null && errorAddUserInGroup != ''">
        <span style="color: red">{{ errorAddUserInGroup }}</span>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            class="float-left"
            @click="$bvModal.hide('modal-add-user-to-group')"
          >
            {{ $t("common.close") }}
          </b-button>
          <b-button
            variant="primary"
            class="float-right btn btn-success"
            @click="onSaveUserInGroup"
          >
            {{ $t("common.confirm") }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <!--  -->
    <!-- modal confirm delete group -->
    <confirm-modal
      ref="confirmDeleteGroupModal"
      :content="confirmDeleteModalContent"
      @onConfirm="onConfirmDeleteGroup()"
    ></confirm-modal>
    <!--  -->
    <!-- modal confirm delete user -->
    <confirm-modal
      ref="confirmDeleteUserModal"
      :content="confirmRemoveModalContent"
      @onConfirm="onClickRemoveUser()"
    ></confirm-modal>
    <!--  -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmModal from "../../components/ConfirmModal";
import SelectOptionSearchUserNotInEvent from "../../components/SelectOptionSearchUserNotInEvent";
export default {
  components: {
    "confirm-modal": ConfirmModal,
    "select-option-search-user-not-in-event": SelectOptionSearchUserNotInEvent,
  },
  props: {
    eventId: {
      type: Number,
      default() {
        return 0;
      },
    },
    pageParams: {
      type: Number,
      default() {
        return 1;
      },
    },
  },
  data: () => ({
    isShow: false,
    isShowGroupDetail: false,
    grid_band_group_data_list: [],
    bandGroupData: {
      id: 0,
      groupID: "",
      groupName: "",
      description: "",
    },
    bandGroupTitle: "",
    userInGroupList: [],
    groupSelected: null,
    confirmDeleteModalContent: "attendance.group.notificationDeleteGroup",
    confirmRemoveModalContent: "attendance.group.notificationRemoveUser",
    userSelected: null,
    itemCustomerGroupBand: {
      data_list: [],
      itemType: 0,
      showLabel: false,
      modifyFromPosition: "margin-top:-300px",
      cssClassSm1: "col-md-12",
      cssClassSm2: "col-sm-12",
      showAddNew: false,
    },
    optionUserSelected: null,
    errorAddUserInGroup: "",
  }),
  watch: {
    GET_BAND_GROUP_LIST_DATA: function () {
      this.grid_band_group_data_list = [];
      this.GET_BAND_GROUP_LIST_DATA.forEach((data, index) => {
        let nonumber = index + 1;
        let group_id = data.group_id;
        let group_name = data.group_name;
        let group_description = data.group_description;
        let number_of_people = data.number_of_people;
        let item = {
          nonumber: nonumber,
          group_id: group_id,
          group_name: group_name,
          group_description: group_description,
          id: data.id,
          number_of_people: number_of_people,
        };
        this.grid_band_group_data_list.push(item);
      });
    },
    GET_USER_IN_BAND_GROUP_DATA: function () {
      this.userInGroupList = [];
      this.GET_USER_IN_BAND_GROUP_DATA.forEach((data, index) => {
        let nonumber = index + 1;
        let full_name =
          data.user_info.last_name + " " + data.user_info.first_name;
        let email = data.user_info.email;
        let phone = data.user_profile.phone;
        let company_name = data.company.company_name;
        let company_position = data.company.company_position;
        let item = {
          nonumber: nonumber,
          full_name: full_name,
          email: email,
          phone: phone,
          company_name: company_name,
          company_position: company_position,
          user_id: data.user,
          group_id: data.group,
        };
        this.userInGroupList.push(item);
      });
    },
  },
  computed: {
    ...mapGetters(["GET_BAND_GROUP_LIST_DATA", "GET_USER_IN_BAND_GROUP_DATA"]),
  },
  methods: {
    showModal: function () {
      this.isShow = true;
      this.GET_BAND_GROUP_LIST();
    },
    showGroupDetail: function (id, item) {
      this.isShowGroupDetail = true;
      if (item != undefined) {
        this.bandGroupTitle = this.$t("attendance.group.groupDetail");
        this.bandGroupData.id = id;
        this.bandGroupData.groupID = item.group_id;
        this.bandGroupData.groupName = item.group_name;
        this.bandGroupData.description = item.group_description;
        const filter = {
          params: {
            group_id: id,
          },
        };
        this.GET_USER_IN_BAND_GROUP(filter);
      } else {
        this.bandGroupTitle = this.$t("attendance.group.groupAddNew");
        this.bandGroupData = {
          id: 0,
          groupID: "",
          groupName: "",
          description: "",
        };
      }
    },
    validateGroupData: function () {
      let validatedResult = "OK";
      for (let key in this.bandGroupData) {
        if (
          this.bandGroupData[key] === null ||
          this.bandGroupData[key] === ""
        ) {
          validatedResult =
            this.$t("common.inputFieldNull") +
            " " +
            this.$t("attendance.group." + key);
          break;
        }
      }
      return validatedResult;
    },
    onSaveGroup: function () {
      let result = this.validateGroupData();
      if (result === "OK") {
        let data = {
          id: this.bandGroupData.id,
          group_id: this.bandGroupData.groupID,
          group_name: this.bandGroupData.groupName,
          group_description: this.bandGroupData.description,
          event_id: sessionStorage.getItem("event_id"),
        };
        if (this.bandGroupData.id == 0) {
          this.CREATE_BAND_GROUP(data)
            .then(
              function () {
                this.isShowGroupDetail = false;
                let userNotInEvent = {
                  params: {
                    page: this.pageParams,
                    event: sessionStorage.getItem("event_id"),
                    getGroup: true,
                  },
                };
                this.GET_BAND_GROUP_LIST(userNotInEvent);
                this.ON_SHOW_TOAST({
                  message: "Success",
                  styleType: "success",
                });
              }.bind(this)
            )
            .catch(
              function (error) {
                let mess_error = error.response.data.message;
                this.ON_SHOW_TOAST({
                  message: mess_error,
                  styleType: "danger",
                });
              }.bind(this)
            );
        } else {
          this.UPDATE_BAND_GROUP(data)
            .then(
              function () {
                this.isShowGroupDetail = false;
                let userNotInEvent = {
                  params: {
                    page: this.pageParams,
                    event: sessionStorage.getItem("event_id"),
                    getGroup: true,
                  },
                };
                this.GET_BAND_GROUP_LIST(userNotInEvent);
                this.ON_SHOW_TOAST({
                  message: "Success",
                  styleType: "success",
                });
              }.bind(this)
            )
            .catch(
              function (error) {
                let mess_error = error.response.data.message;
                this.ON_SHOW_TOAST({
                  message: mess_error,
                  styleType: "danger",
                });
              }.bind(this)
            );
        }
      } else {
        this.ON_SHOW_TOAST({
          message: result,
          styleType: "danger",
        });
      }
    },
    onShowModalConfirmDeleteGroup: function (data) {
      this.groupSelected = data;
      this.$refs.confirmDeleteGroupModal.onShowModalConfirm();
    },
    onConfirmDeleteGroup: function () {
      this.DELETE_BAND_GROUP(this.groupSelected)
        .then(
          function () {
            this.GET_BAND_GROUP_LIST();
            let userNotInEvent = {
              params: {
                page: this.pageParams,
                event: sessionStorage.getItem("event_id"),
                getGroup: true,
              },
            };
            this.GET_USER_NOT_IN_EVENT(userNotInEvent);
            this.ON_SHOW_TOAST({
              message: "Success",
              styleType: "success",
            });
          }.bind(this)
        )
        .catch(
          function (error) {
            let mess_error = error.response.data.message;
            this.ON_SHOW_TOAST({
              message: mess_error,
              styleType: "danger",
            });
          }.bind(this)
        );
    },
    // user in group
    // remove user
    onShowModalRemoveUser: function (data) {
      this.userSelected = data;
      this.$refs.confirmDeleteUserModal.onShowModalConfirm();
    },
    onClickRemoveUser: function () {
      this.REMOVE_USER_FROM_GROUP(this.userSelected)
        .then(
          function () {
            const filter = {
              params: {
                group_id: this.bandGroupData.id,
              },
            };
            this.GET_USER_IN_BAND_GROUP(filter);
            this.ON_SHOW_TOAST({
              message: "Success",
              styleType: "success",
            });
          }.bind(this)
        )
        .catch(
          function (error) {
            let mess_error = error.response.data.message;
            this.ON_SHOW_TOAST({
              message: mess_error,
              styleType: "danger",
            });
          }.bind(this)
        );
    },
    // add user in group
    showModalAddUserInGroup: function () {
      this.$refs["modal-add-user-to-group"].show();
      this.optionUserSelected = null;
      this.errorAddUserInGroup = ''
    },
    // select user
    onchangeselect: function (data) {
      this.optionUserSelected = data;
    },
    onSaveUserInGroup: function () {
      if (this.optionUserSelected == null) {
        let mess =
          this.$t("common.inputFieldNull") +
          " " +
          this.$t("attendance.group.selectUser");
        this.errorAddUserInGroup = mess
        this.ON_SHOW_TOAST({
          message: mess,
          styleType: "danger",
        });
      } else {
        let data = {
          group_id: this.bandGroupData.id,
          user_id: this.optionUserSelected,
        };
        this.ADD_USER_INTO_GROUP(data)
          .then(
            function () {
              this.$refs["modal-add-user-to-group"].hide();
              const filter = {
                params: {
                  group_id: this.bandGroupData.id,
                },
              };
              this.GET_USER_IN_BAND_GROUP(filter);
            }.bind(this)
          )
          .catch(
            function (error) {
              let mess = error.response.data.message
              if (error.response.data.code=='user_already_exists_in_the_group'){
                mess = this.$t('attendance.group.userAlreadyExistsInTheGroup')
              }
              this.errorAddUserInGroup = mess
              this.ON_SHOW_TOAST({
                message: mess,
                styleType: "danger",
              });
            }.bind(this)
          );
      }
    },
    ...mapActions([
      "ON_SHOW_TOAST",
      "GET_BAND_GROUP_LIST",
      "CREATE_BAND_GROUP",
      "UPDATE_BAND_GROUP",
      "DELETE_BAND_GROUP",
      "REMOVE_USER_FROM_GROUP",
      "GET_USER_IN_BAND_GROUP",
      "GET_USER_NOT_IN_EVENT",
      "ADD_USER_INTO_GROUP",
    ]),
  },
};
</script>
<style scoped>
tr td:last-child {
  width: 1%;
  white-space: nowrap;
}
tr td:first-child {
  width: 1%;
  white-space: nowrap;
}
</style>