<template>
  <div>
    <!--BEGIN DROPDOWN Custom-->
    <div class="form-group" style="margin-bottom:0px;">
      <label
        v-show="item.showLabel"
        for="location"
        class="control-label"
        :class="[item.cssClassSm1, (item.required == true || item.required == undefined) ? 'required' : '']"
      >{{item.titleLabel != undefined ? item.titleLabel : $t('property.asset.type')}}</label>
      <!-- dropdown -->
      <div class="dropdown" :class="item.cssClassSm2" style="padding: 0">
        <b-form-input
          @click="showAdvanceFilter"
          :placeholder="placeHolder"
          :value="labelShowText"
          required
          class="form-control"
          type="text"
          data-toggle="dropdown"
          readonly
          style="background:white"
        />
        <div
          v-if="isShowAdvanceFilter"
          v-show="isShowAdvanceFilter"
          v-click-outside="outside"
          @click="inside"
          class="dropdown-menu dropdown"
          style="width:100%;z-index:1;"
        >
          <!-- title -->
          <div class="dropdown-title">
            <label
              style=" display: block;
                          text-align: center;
                          line-height: 80%;"
              for="selecttype"
              class="control-label"
            >{{dropdown_titleLte}}</label>
            <button
              class="dropdown-title-button dropdown-menu-close"
              type="button"
              @click="closeAdvanceFilter"
            >
              <i aria-hidden="true" data-hidden="true" class="fa fa-times dropdown-menu-close-icon"></i>
            </button>
          </div>
          <!-- Search form -->
          <div class="md-form">
            <input
              style=" width:100%;
                            text-align:left;
                            margin-right: auto;
                            margin-left: auto;
                            min-height: 30px;
                            <!-- margin-top:-15px; -->
                            padding: 0 7px;
                            color: #555;
                            line-height: 30px;
                            <!-- border: 1px solid #dfdfdf; -->
                            border-radius: 2px;
                            outline: 0;"
              class="form-control dropdown-input-field"
              type="text"
              :placeholder="$t('userProfile.email')"
              aria-label="Search"
              v-model="strSearch"
              autocomplete="on"
              @keydown.enter="inputSearchText($event)"
              ref="inputSearch"
            />
            <i
              aria-hidden="true"
              data-hidden="true"
              class="fa fa-search"
              :class="{'fa-times':changeIcon}"
              @click="inputSearchText($event)"
              style="float:right;margin:-24px 15px;cursor: pointer;"
            ></i>
          </div>
          <!-- Content -->
          <!--  -->
          <div class="dropdown-content" style="margin-top:20px;" v-if="userList.length > 0">
            <ul>
              <div class="box-body table-responsive no-padding">
                <table class="table table-hover" style="width:100%;">
                  <tbody>
                    <tr>
                      <th>{{$t('userProfile.fullName')}}</th>
                      <th>{{$t('userProfile.email')}}</th>
                    </tr>
                    <tr
                      v-for="itemtype in userList"
                      :key="itemtype.id"
                      @click="selectItem(itemtype.id, itemtype.full_name)"
                    >
                      <td>{{itemtype.full_name}}</td>
                      <td>{{itemtype.email}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ul>
          </div>
          <div v-else class="dropdown-content" style="margin-top:20px; text-align: center; padding-bottom: 10px">
            <span for="" style="color: red">{{ $t('common.not_found_email_info') }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- END DROPDOWN Custom-->

    <!--BEGIN FORM ADD NEW ASSET Custom -->
    <div class="form-group" style="border:0px solid red;max-height:0px;margin-top:0px;">
      <label for="location" class="control-label" :class="item.cssClassSm1"></label>
      <div class="dropdown" :class="item.cssClassSm2">
        <div
          v-if="isShowNewItemType"
          v-show="isShowNewItemType"
          v-click-outside="outside"
          @click="inside"
          class="dropdown-menu dropdown"
          id="your-custom-id-material"
          style="
                        width:95%;
                        z-index:2;
                        min-height: 40px;
                        max-height: 312px;"
          :style="item.modifyFromPosition"
        ></div>
      </div>
    </div>
    <!-- END FORM ADD NEW ASSET Custom -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import utils from "../utils/functionUtils";

export default {
  name: "SelectOptionTable",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    placeHolder: {
      type: String,
      default() {
        return "";
      },
    },
    dropdown_titleLte: {
      type: String,
      default() {
        return "";
      },
    },
    searchItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      data_list: null,
      isShowAdvanceFilter: false,
      isShowNewItemType: false,
      opened: false,
      item_type: [],
      strSearch: "",
      changeIcon: false,
      itemType: null,
      keepItemType: 1,
      itemtypedata: {
        name: "",
      },
      //
      userList: [],
      labelShowText: "",
    };
  },
  watch: {
    searchItem: async function () {
      await this.showLabel();
    },
  },
  methods: {
    ...mapActions(["ERROR_TOAST", "SUCCESS_TOAST", "GET_ALL_ACCOUNT"]),
    create() {
      this.data_list = this.item.data_list;
      // this.itemType = this.item.itemType;
    },

    showAdvanceFilter: function () {
      this.isShowAdvanceFilter = true;
      this.opened = true;
    },
    outside: function () {
      if (this.opened) {
        //console.log("clicked outside!");
        this.opened = false;
        this.$refs.inputSearch.focus();
      } else {
        this.isShowAdvanceFilter = false;
        this.isShowNewItemType = false;
      }
    },
    inside: function () {
      //console.log("clicked inside!");
      if (this.opened) {
        this.isShowNewItemType
          ? (this.isShowAdvanceFilter = false)
          : (this.isShowAdvanceFilter = true);
      }
    },
    closeAdvanceFilter: function () {
      this.opened = false;
      this.isShowAdvanceFilter = false;
    },
    getItemType: function (value) {
      this.item.itemType = value;
      this.itemType = value;
      this.$emit("clickOnItem", true);
      this.outside();
    },
    setNoItemType: function () {
      this.itemType = 0;
    },
    onChangeSearchItemType: function () {
      if (this.strSearch.length > 0) {
        this.changeIcon = true;
      } else {
        this.changeIcon = false;
      }
    },
    emptySearch: function () {
      this.strSearch = "";
      this.changeIcon = false;
    },
    emtyAllAfterComfirm: function () {
      this.setNoItemType();
      this.emptySearch();
    },
    //
    inputSearchText: function (event) {
      event.preventDefault();
      if (!utils.isEmptyString(this.strSearch)) {
        let filter = {
          params: {
            searchText: this.strSearch,
            event: sessionStorage.getItem("event_id")
          },
        };
        this.GET_ALL_ACCOUNT(filter).then(
          function (res) {
            let data = res.data;
            this.userList = [];
            data.forEach((user) => {
              let item = {
                id: user.id,
                full_name: user.lastname + " " + user.firstname,
                email: user.email,
              };
              this.userList.push(item);
            });
          }.bind(this)
        );
      }
    },
    selectItem: function (value, full_name) {
      this.labelShowText = full_name;
      this.$emit("onchangeselect", value);
      this.outside();
    },
    showLabel: async function () {
      if (await this.searchItem.full_name) {
        this.labelShowText = this.searchItem.full_name;
        this.userList = [this.searchItem];
      }
    },
  },
  async mounted() {
    this.create();
    await this.showLabel();
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== "function") {
          const compName = vNode.context.name;
          // let warn = `[Vue-click-outside:] provided expression '${
          //   binding.expression
          // }' is not a function, but has to be`;
          if (compName) {
            // warn += `Found in component '${compName}'`;
          }
          //console.warn(warn);
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
        // add Event Listeners
        document.addEventListener("click", handler);
      },

      unbind: function (el) {
        // Remove Event Listeners
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.dropdown .dropdown-menu,
.dropdown .dropdown-menu-nav {
  transform: translateY(0);
  display: block;
  min-height: 40px;
  // max-height: 312px;
  overflow-y: auto;
  // z-index: 300;
  margin-top: 4px;
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.dropdown-menu-labels .dropdown-content {
  max-height: 135px;
}
.dropdown-menu .divider,
.dropdown-menu-nav .divider {
  height: 1px;
  margin: 4px 0;
  padding: 0;
  background-color: #dfdfdf;
}

.dropdown-content {
  max-height: 252px;
  overflow-y: auto;
}

.dropdown-menu ul,
.dropdown-menu-nav ul {
  margin: 0;
  padding: 0;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.dropdown-menu li,
.dropdown-menu-nav li {
  display: block;
  text-align: left;
  list-style: none;
  padding: 0 1px;
}

.dropdown-menu li a,
.dropdown-menu li button,
.dropdown-menu li .menu-item,
.dropdown-menu-nav li a,
.dropdown-menu-nav li button,
.dropdown-menu-nav li .menu-item {
  background: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  display: block;
  font-weight: 400;
  position: relative;
  padding: 8px 12px;
  color: #2e2e2e;
  line-height: 16px;
  white-space: normal;
  overflow: hidden;
  text-align: left;
  width: 98%;
}

.dropdown-menu a,
.dropdown-menu button,
.dropdown-menu-nav a {
  transition: none;
}
.dropdown-content ul li:hover,
.dropdown-footer ul li:hover a {
  background-color: #eee;
  color: #2e2e2e;
  outline: 0;
  text-decoration: none;
}
.dropdown-input-field:focus {
  color: #555;
  border-color: #73afea;
  box-shadow: 0 0 4px rgba(115, 175, 234, 0.4);
}

.dropdown-title {
  position: relative;
  padding: 8px 12px;
  padding-bottom: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #dfdfdf;
  overflow: hidden;
}

.dropdown-title-button {
  position: absolute;
  top: 0;
  padding: 0;
  color: #bfbfbf;
  font-size: 14px;
  border: 0;
  background: none;
  outline: 0;
  margin: 10px 0px 0px 40%;
}

.form-goback-title-button {
  position: absolute;
  top: 0;
  padding: 0;
  color: #bfbfbf;
  font-size: 14px;
  border: 0;
  background: none;
  outline: 0;
}

.form-close-title-button {
  position: absolute;
  top: 0;
  padding: 0;
  color: #bfbfbf;
  font-size: 14px;
  border: 0;
  background: none;
  outline: 0;
}

.dropdown-menu-close {
  right: 5px;
  width: 20px;
  height: 20px;
  top: -1px;
}

.dropdown-menu-back {
  left: 7px;
  top: 2px;
}

.dropdown-labels-error {
  padding: 5px 10px;
  margin-bottom: 10px;
  background-color: #db3b21;
  color: #fff;
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  min-height: 30px;
  padding: 0px 7px;
}

label.required:not(:empty):after,
.field-header.required:after {
  content: " *";
  color: red;
  position: absolute;
  right: 5px;
  top: 0px;
}
</style>

